// /web/src/templates/category.js
import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PortableText from '../components/portableText'

import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'


// Add “posts” to the GraphQL query
export const query = graphql`
  query AuthorTemplateQuery($id: String!) {
    category: sanityAuthor(id: {eq: $id}) {
      name
      _rawBio
    }
  }
`
const CategoryPostTemplate = props => {
  const {data = {}, errors} = props
  // Destructure the new posts property from props
  const {name, _rawBio} = data.category || {}

  return (
    <Layout>
      <Container>
        {errors && <GraphQLErrorList errors={errors} />}
        {!data.category && <p>No category data</p>}
        <SEO title={'About '+name} />
        <h1 className={responsiveTitle1}>About {name}</h1>
        <p>{<PortableText blocks={_rawBio} />}</p>
      </Container>
    </Layout>
  )
}

export default CategoryPostTemplate